const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const facetBarFields = [...wheelsFields, ...tiresFields];
const ignoreFields = [];

const vehicleStore = globalThis.Convermax?.fitmentSearch?.vehicleStore;

// because Vehicle field on landings is sending from the Client, we need to hide it manually from chips
if (vehicleStore) {
  ignoreFields.push('Vehicle');
}

globalThis.Convermax.facetPanelIgnoreField = (field) => facetBarFields.includes(field);

const updateCallback = () => {
  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }
};

const fieldsForRelated = ['collections', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  platform: 'shopify',
  SearchRequestDefaults: {
    pageSize: 36,
    selection: vehicleStore ? [{ field: 'Vehicle', term: vehicleStore }] : undefined,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    expectResponseRedirect: true,
  },
  getLocalPreselection: (pageType, defaults) => (vehicleStore ? null : defaults.getter(pageType)),
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.collection .products, body.collection-universal .products',
      template: 'SearchPage',
    },
    {
      name: 'MiniStorePage',
      type: 'SearchPage',
      location: 'body.collection-mini-store .cm_mini-store',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    { name: 'SearchResult', updateCallback },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      location: { replace: 'body #search form' },
      template: 'SearchBox',
      onSubmit: () => window.document.querySelector('#cm_SearchBox .search-close')?.click(),
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        insertAfter: '.mobile-header .icon-menu',
        class: 'cm_searchBoxDialogButton_container',
        wrapper: 'a',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        replace: 'body.index #shopify-section-1615578139c21ab10d .homepage_content .container > div',
        class: 'cm_vehicle-widget_container__home sixteen columns',
      },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: { insertBefore: '.header .menu .cart', wrapper: 'li' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        insertBefore: '.mobile-header .cart-button',
        class: 'right',
        wrapper: 'a',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'RelatedItems',
      location: {
        replace: '.products',
        class: 'products',
      },
      template: 'product/relatedItems',
      visibleIf: () => window.ShopifyAnalytics.meta.page.pageType === 'product',
      count: 3,
      getSelection,
    },
    {
      name: 'FitmentTable',
      location: { insertAfter: '.description' },
      template: 'fitmentSearch/fitmentTable',
      visibleIf: () =>
        window.ShopifyAnalytics.meta.page.pageType === 'product' &&
        !window.document.querySelector('.description')?.textContent?.includes('This Part Fits'),
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
